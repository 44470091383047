import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationAddContractAttachmentArgs,
  MutationAddContractTierArgs,
  MutationAddContractTierRebateArgs,
  MutationCreateDraftContractArgs,
  MutationDownloadContractAttachmentArgs,
  MutationGetContractPartUploadTemplateArgs,
  MutationGetContractXrefUploadTemplateArgs,
  MutationProcessContractUploadArgs,
  MutationProcessContractXrefUploadArgs,
  MutationRemoveContractAttachmentArgs,
  MutationRemoveContractMemberTierHistoriesArgs,
  MutationRemoveContractPartsArgs,
  MutationRemoveContractPartTierHistoriesArgs,
  MutationRemoveContractTierArgs,
  MutationRemoveContractTierRebateArgs,
  MutationSubmitContractArgs,
  MutationUpdateContractAttachmentArgs,
  MutationUpdateContractBasketsArgs,
  MutationUpdateContractMembersArgs,
  MutationUpdateContractMemberTierHistoriesArgs,
  MutationUpdateContractPartsArgs,
  MutationUpdateContractPartTierHistoriesArgs,
  MutationUpdateContractWithCrossReferenceListArgs,
  MutationUpdateDraftContractArgs,
  MutationUploadContractFileArgs,
  MutationUploadContractXrefFileArgs,
  Query,
  QueryContractArgs,
  QueryContractHistoriesArgs,
  QueryContractMemberArgs,
  QueryContractMembersArgs,
  QueryContractMemberTierHistoriesArgs,
  QueryContractsArgs,
  QueryContractSubCategoriesArgs,
  QueryMemberAnalysisArgs,
  QueryPartTiersHistoriesArgs,
} from '../gen/models'
import { TierFragment, TierRebateFragment, TierRuleFragment } from './tiers'
import { UploadJobFullFragment } from './upload-job'

export const ContractFragment = gql`
  fragment ContractFragment on Contract {
    id
    name
    code
    status
    supplier
    description
    note
    sourcingOfficer
    activeDue
    activeSince
    category
    subCategories
    createdAt
    updatedAt
    customer {
      id
    }
    numTiers
    extras
    autoRenew
  }
`

export const PartFragment = gql`
  fragment PartFragment on Part {
    tsId
    vendor
    venitem
    manufacturer
    mfgitem
    description
    stanId
    gudId
    commodityId
    createdAt
    updatedAt
  }
`

export const PartTierFragment = gql`
  fragment PartTierFragment on PartTier {
    partsTsId
    tiersId
    price
    uom
    uomConversion
    effectiveStartDate
    effectiveEndDate
  }
`

export const PartFragmentWithPartTier = gql`
  fragment PartFragmentWithPartTier on Part {
    ...PartFragment
    partTiers {
      ...PartTierFragment
    }
  }
  ${PartFragment}
  ${PartTierFragment}
`

const ContractAttachmentFragment = gql`
  fragment ContractAttachmentFragment on ContractAttachment {
    id
    name
    description
    s3Key
    type
    createdAt
  }
`

const ContractMemberFragment = gql`
  fragment ContractMemberFragment on ContractMember {
    id
    name
    tierName
    declinedBy
  }
`

const CONTRACTS_QUERY = gql`
  query contracts($input: GetContractsByCriteriaInput!, $pagination: Pagination) {
    contracts(input: $input, pagination: $pagination) {
      hits {
        ...ContractFragment
        numTiers
        editable
      }
      totalHits
    }
  }
  ${ContractFragment}
`

export const useContractsQuery = (baseOptions: QueryHookOptions<Pick<Query, 'contracts'>, QueryContractsArgs>) =>
  useQuery(CONTRACTS_QUERY, baseOptions)

export const CONTRACT_QUERY = gql`
  query contract($input: IDArgs!) {
    contract(input: $input) {
      ...ContractFragment
      partsCount
      editable
      hasCustomEffectiveDates
      manufacturers {
        normalizedManufacturer
        basketId
      }
      crossReferenceListId
      crossReferenceList {
        id
        name
      }
      organizations {
        tiersId
        facilityId
        organizationsId
        organization {
          id
          name
        }
      }
      attachments {
        ...ContractAttachmentFragment
      }
      tiers {
        tiersId
        extras
        tier {
          ...TierFragment
          description
          createdAt
          updatedAt
          readableRules
          advancedRulesJsonLogic
          rules {
            name
            ...TierRuleFragment
          }
        }
      }
      rebates {
        ...TierRebateFragment
        rules {
          ...TierRuleFragment
        }
      }
      latestUploadJob {
        ...UploadJobFullFragment
      }
      latestXrefUploadJob {
        ...UploadJobFullFragment
      }
      baskets {
        id
        name
        insights {
          totalSpend
          numItems
        }
        createdAt
        archivedAt
      }
    }
  }
  ${ContractFragment}
  ${TierFragment}
  ${TierRuleFragment}
  ${TierRebateFragment}
  ${ContractAttachmentFragment}
  ${UploadJobFullFragment}
`

export const useContractQuery = (baseOptions: QueryHookOptions<Pick<Query, 'contract'>, QueryContractArgs>) =>
  useQuery(CONTRACT_QUERY, baseOptions)

const CREATE_DRAFT_CONTRACT = gql`
  mutation createDraftContract($input: CreateContractInput!) {
    createDraftContract(input: $input) {
      ...ContractFragment
    }
  }
  ${ContractFragment}
`

export function useSaveDraftContractMutation(
  options?: MutationHookOptions<Pick<Mutation, 'createDraftContract'>, MutationCreateDraftContractArgs>,
) {
  return useMutation<Pick<Mutation, 'createDraftContract'>, MutationCreateDraftContractArgs>(
    CREATE_DRAFT_CONTRACT,
    options,
  )
}

const UPDATE_DRAFT_CONTRACT = gql`
  mutation updateDraftContract($input: UpdateContractInput!) {
    updateDraftContract(input: $input) {
      ...ContractFragment
    }
  }
  ${ContractFragment}
`

export function useUpdateDraftContractMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateDraftContract'>, MutationUpdateDraftContractArgs>,
) {
  return useMutation<Pick<Mutation, 'updateDraftContract'>, MutationUpdateDraftContractArgs>(
    UPDATE_DRAFT_CONTRACT,
    options,
  )
}

const SUBMIT_CONTRACT = gql`
  mutation submitContract($input: SubmitContractInput!) {
    submitContract(input: $input)
  }
`

export function useSubmitContractMutation(
  options?: MutationHookOptions<Pick<Mutation, 'submitContract'>, MutationSubmitContractArgs>,
) {
  return useMutation<Pick<Mutation, 'submitContract'>, MutationSubmitContractArgs>(SUBMIT_CONTRACT, options)
}

const ADD_CONTRACT_TIER = gql`
  mutation addContractTier($input: CreateContractTierInput!) {
    addContractTier(input: $input)
  }
`

export function useAddContractTierMutation(
  options?: MutationHookOptions<Pick<Mutation, 'addContractTier'>, MutationAddContractTierArgs>,
) {
  return useMutation<Pick<Mutation, 'addContractTier'>, MutationAddContractTierArgs>(ADD_CONTRACT_TIER, options)
}

const REMOVE_CONTRACT_TIER = gql`
  mutation removeContractTier($input: RemoveContractTierInput!) {
    removeContractTier(input: $input)
  }
`

export function useRemoveContractTierMutation(
  options?: MutationHookOptions<Pick<Mutation, 'removeContractTier'>, MutationRemoveContractTierArgs>,
) {
  return useMutation<Pick<Mutation, 'removeContractTier'>, MutationRemoveContractTierArgs>(
    REMOVE_CONTRACT_TIER,
    options,
  )
}

const UPDATE_PARTS = gql`
  mutation updateContractParts($input: UpdateContractPartsInput!) {
    updateContractParts(input: $input) {
      rowId
      part {
        ...PartFragmentWithPartTier
      }
      category
      subCategory
    }
  }
  ${PartFragmentWithPartTier}
`

export function useUpdateContractPartsMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateContractParts'>, MutationUpdateContractPartsArgs>,
) {
  return useMutation(UPDATE_PARTS, options)
}

const REMOVE_PARTS = gql`
  mutation removeContractParts($input: RemoveContractPartsInput!) {
    removeContractParts(input: $input)
  }
`

export function useRemoveContractPartsMutation(
  options?: MutationHookOptions<Pick<Mutation, 'removeContractParts'>, MutationRemoveContractPartsArgs>,
) {
  return useMutation(REMOVE_PARTS, options)
}
const UPLOAD_CONTRACT_FILE = gql`
  mutation uploadContractFile($input: UploadContractInput!) {
    uploadContractFile(input: $input) {
      s3Key
      data {
        columnName
        previewData
        sheetName
        sheetIndex
      }
    }
  }
`

export const useUploadContractFile = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'uploadContractFile'>, MutationUploadContractFileArgs>,
) => useMutation(UPLOAD_CONTRACT_FILE, baseOptions)

const PROCESS_CONTRACT_UPLOAD = gql`
  mutation processContractUpload($input: ProcessContractUploadInput!) {
    processContractUpload(input: $input)
  }
`

export const useProcessContractUpload = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'processContractUpload'>, MutationProcessContractUploadArgs>,
) => useMutation(PROCESS_CONTRACT_UPLOAD, baseOptions)

const UPDATE_CONTRACT_MEMBERS = gql`
  mutation updateContractMembers($input: UpdateContractMembersInput!) {
    updateContractMembers(input: $input) {
      id
      name
      memberId
      tierId
      tierName
      contractId
      tierDescription
      topParentId
      topParentName
      userFirstName
      userLastName
      userEmail
      priceActivationDate
      priceDeactivationDate
      declinedBy
      declinedByFirstName
      declinedByLastName
      declinedByEmail
    }
  }
`

export function useUpdateContractMemberssMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateContractMembers'>, MutationUpdateContractMembersArgs>,
) {
  return useMutation(UPDATE_CONTRACT_MEMBERS, options)
}

const ADD_CONTRACT_ATTACHMENT = gql`
  mutation addContractAttachment($input: AddContractAttachmentInput!) {
    addContractAttachment(input: $input) {
      ...ContractAttachmentFragment
    }
  }
  ${ContractAttachmentFragment}
`

export function useAddContractAttachmentMutation(
  options?: MutationHookOptions<Pick<Mutation, 'addContractAttachment'>, MutationAddContractAttachmentArgs>,
) {
  return useMutation<Pick<Mutation, 'addContractAttachment'>, MutationAddContractAttachmentArgs>(
    ADD_CONTRACT_ATTACHMENT,
    options,
  )
}

const UPDATE_CONTRACT_ATTACHMENT = gql`
  mutation updateContractAttachment($input: UpdateContractAttachmentInput!) {
    updateContractAttachment(input: $input) {
      ...ContractAttachmentFragment
    }
  }
  ${ContractAttachmentFragment}
`

export function useUpdateContractAttachmentMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateContractAttachment'>, MutationUpdateContractAttachmentArgs>,
) {
  return useMutation<Pick<Mutation, 'updateContractAttachment'>, MutationUpdateContractAttachmentArgs>(
    UPDATE_CONTRACT_ATTACHMENT,
    options,
  )
}

const REMOVE_CONTRACT_ATTACHMENT = gql`
  mutation removeContractAttachment($ids: [String!]!) {
    removeContractAttachment(ids: $ids)
  }
`

export function useRemoveContractAttachmentMutation(
  options?: MutationHookOptions<Pick<Mutation, 'removeContractAttachment'>, MutationRemoveContractAttachmentArgs>,
) {
  return useMutation<Pick<Mutation, 'removeContractAttachment'>, MutationRemoveContractAttachmentArgs>(
    REMOVE_CONTRACT_ATTACHMENT,
    options,
  )
}

const CONTRACT_MEMBERS_QUERY = gql`
  query contractMembers($input: GetContractMembersInput!) {
    contractMembers(input: $input) {
      ...ContractMemberFragment
    }
  }
  ${ContractMemberFragment}
`

export const useContractMembersQuery = (
  baseOptions: QueryHookOptions<Pick<Query, 'contractMembers'>, QueryContractMembersArgs>,
) => useQuery(CONTRACT_MEMBERS_QUERY, baseOptions)

const CONTRACT_MEMBER_QUERY = gql`
  query contractMember($input: GetContractMemberInput!) {
    contractMember(input: $input) {
      ...ContractMemberFragment
      parentManufacturersMarketShare {
        normalizedManufacturer
        totalSpend
        marketShare
      }
      extendedStats {
        manufacturersMarketShare {
          normalizedManufacturer
          totalSpend
          marketShare
        }

        last12MonthsSpend {
          month
          totalSpend
        }
      }
    }
  }
  ${ContractMemberFragment}
`

export const useContractMemberQuery = (
  baseOptions: QueryHookOptions<Pick<Query, 'contractMember'>, QueryContractMemberArgs>,
) => useQuery(CONTRACT_MEMBER_QUERY, baseOptions)

const CONTRACT_HISTORIES_QUERY = gql`
  query contractHistories($input: ContractHistoryInput!, $pagination: Pagination, $sort: Sort) {
    contractHistories(input: $input, pagination: $pagination, sort: $sort) {
      hits {
        log {
          id
          actor {
            firstName
            lastName
            email
            avatarUrl
          }
          createdAt
        }
        descriptiveActions {
          action
          object
          changes {
            key
            before
            after
          }
        }
      }
      total
    }
  }
`

export const useContractHistoriesQuery = (
  baseOptions: QueryHookOptions<Pick<Query, 'contractHistories'>, QueryContractHistoriesArgs>,
) => useQuery(CONTRACT_HISTORIES_QUERY, baseOptions)

const UPLOAD_CONTRACT_XREF_FILE = gql`
  mutation uploadContractXrefFile($input: UploadContractInput!) {
    uploadContractXrefFile(input: $input) {
      s3Key
      data {
        columnName
        previewData
        sheetName
        sheetIndex
      }
    }
  }
`

export const useUploadContractXrefFile = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'uploadContractXrefFile'>, MutationUploadContractXrefFileArgs>,
) => useMutation(UPLOAD_CONTRACT_XREF_FILE, baseOptions)

const PROCESS_CONTRACT_XREF_UPLOAD = gql`
  mutation processContractXrefUpload($input: ProcessContractXrefUploadInput!) {
    processContractXrefUpload(input: $input)
  }
`

export const useProcessContractXrefUpload = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'processContractXrefUpload'>, MutationProcessContractXrefUploadArgs>,
) => useMutation(PROCESS_CONTRACT_XREF_UPLOAD, baseOptions)

const UPDATE_CONTRACT_WITH_CROSS_REFERENCE_LIST = gql`
  mutation updateContractWithCrossReferenceList($id: String!, $crossReferenceListId: String) {
    updateContractWithCrossReferenceList(id: $id, crossReferenceListId: $crossReferenceListId)
  }
`

export const useUpdateContractWithCrossReferenceListMutation = (
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'updateContractWithCrossReferenceList'>,
    MutationUpdateContractWithCrossReferenceListArgs
  >,
) => useMutation(UPDATE_CONTRACT_WITH_CROSS_REFERENCE_LIST, baseOptions)

const MEMBER_ANALYSIS_QUERY = gql`
  query memberAnalysis($input: MemberAnalysisInput!, $pagination: Pagination!) {
    memberAnalysis(input: $input, pagination: $pagination) {
      facility_name
      type
      contract_price_diff
      perc_contract_price_diff
      price_diff_after_rebates
      perc_price_diff_after_rebates
      s_vendor
      s_venitem
      s_manufacturer
      s_mfgitem
      s_description
      s_uom
      s_uomconversion
      e_vendor
      e_venitem
      e_manufacturer
      e_mfgitem
      e_description
      e_uom
      e_uomconversion
      last_price
      qtypurchase
      extprice
      projected_spend
      last_date_purchase
      contract_name
      contract_description
      contract_supplier
      sourcing_officer
      contract_start
      contract_end
      contract_mfg
      contract_mfgitem
      tier
      tier_price
      tier_rebate
      tier_uom
      tier_uom_conversion
    }
  }
`

export const useMemberAnalysisQuery = (
  baseOptions: QueryHookOptions<Pick<Query, 'memberAnalysis'>, QueryMemberAnalysisArgs>,
) => useQuery(MEMBER_ANALYSIS_QUERY, baseOptions)

const DOWNLOAD_CONTRACT_ATTACHMENT = gql`
  mutation downloadContractAttachment($id: String!) {
    downloadContractAttachment(id: $id)
  }
`

export const useDownloadContractAttachmentMutation = (
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'downloadContractAttachment'>,
    MutationDownloadContractAttachmentArgs
  >,
) => useMutation(DOWNLOAD_CONTRACT_ATTACHMENT, baseOptions)

const CONTRACT_CATEGORIES_QUERY = gql`
  query contractCategories {
    contractCategories
  }
`

export const useGetContractCategories = (baseOptions: QueryHookOptions<Pick<Query, 'contractCategories'>>) => {
  return useQuery(CONTRACT_CATEGORIES_QUERY, baseOptions)
}

const CONTRACT_SUB_CATEGORIES_QUERY = gql`
  query contractSubCategories($category: String) {
    contractSubCategories(category: $category)
  }
`

export const useGetContractSubCategories = (
  baseOptions: QueryHookOptions<Pick<Query, 'contractSubCategories'>, QueryContractSubCategoriesArgs>,
) => {
  return useQuery(CONTRACT_SUB_CATEGORIES_QUERY, baseOptions)
}

const UPDATE_CONTRACT_BASKETS_MUTATION = gql`
  mutation updateContractBaskets($input: UpdateContractBasketsInput!) {
    updateContractBaskets(input: $input) {
      id
    }
  }
`

export function useUpdateContractBasketsMutation(
  baseOptions?: MutationHookOptions<Pick<Mutation, 'updateContractBaskets'>, MutationUpdateContractBasketsArgs>,
) {
  return useMutation(UPDATE_CONTRACT_BASKETS_MUTATION, baseOptions)
}

const CONTRACT_MEMBER_TIER_HISTORY_QUERY = gql`
  query contractMemberTierHistories($memberId: String!) {
    contractMemberTierHistories(memberId: $memberId) {
      id
      tier {
        name
        description
      }
      priceActivationDate
      priceDeactivationDate
    }
  }
`

export const useContractMemberTierHistoriesQuery = (
  baseOptions: QueryHookOptions<Pick<Query, 'contractMemberTierHistories'>, QueryContractMemberTierHistoriesArgs>,
) => useQuery(CONTRACT_MEMBER_TIER_HISTORY_QUERY, baseOptions)

const UPDATE_CONTRACT_MEMBER_TIER_HISTORIES_MUTATION = gql`
  mutation updateContractMemberTierHistories($input: [UpdateHistoriesInput!]!) {
    updateContractMemberTierHistories(input: $input) {
      id
      priceActivationDate
      priceDeactivationDate
    }
  }
`

export function useUpdateContractMemberTierHistoriesMutation(
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'updateContractMemberTierHistories'>,
    MutationUpdateContractMemberTierHistoriesArgs
  >,
) {
  return useMutation(UPDATE_CONTRACT_MEMBER_TIER_HISTORIES_MUTATION, baseOptions)
}

const REMOVE_CONTRACT_MEMBER_TIER_HISTORIES_MUTATION = gql`
  mutation removeContractMemberTierHistories($ids: [String!]!) {
    removeContractMemberTierHistories(ids: $ids)
  }
`

export function useRemoveContractMemberTierHistoriesMutation(
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'removeContractMemberTierHistories'>,
    MutationRemoveContractMemberTierHistoriesArgs
  >,
) {
  return useMutation(REMOVE_CONTRACT_MEMBER_TIER_HISTORIES_MUTATION, baseOptions)
}

const CONTRACT_ANALYSIS_LAST_UPDATED_AT_QUERY = gql`
  query contractMemberAnalysisLastUpdatedAt {
    contractMemberAnalysisLastUpdatedAt
  }
`

export const useContractMemberAnalysisLastUpdatedAtQuery = (
  baseOptions?: QueryHookOptions<Pick<Query, 'contractMemberAnalysisLastUpdatedAt'>>,
) => useQuery(CONTRACT_ANALYSIS_LAST_UPDATED_AT_QUERY, baseOptions)

const QUEUE_CONTRACT_MATCHING_SPEND_UPDATE_JOB_MUTATION = gql`
  mutation queueContracMatchingSpendsUpdateJob {
    queueContracMatchingSpendsUpdateJob {
      id
    }
  }
`

export function useQueueContracMatchingSpendsUpdateJobMutation(
  baseOptions?: MutationHookOptions<Pick<Mutation, 'queueContracMatchingSpendsUpdateJob'>>,
) {
  return useMutation(QUEUE_CONTRACT_MATCHING_SPEND_UPDATE_JOB_MUTATION, baseOptions)
}

const GET_CONTRACT_PART_UPLOAD_TEMPLETE = gql`
  mutation getContractPartUploadTemplate($contractId: String!) {
    getContractPartUploadTemplate(contractId: $contractId)
  }
`

export function useGetContractPartUploadTemplateMutation(
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'getContractPartUploadTemplate'>,
    MutationGetContractPartUploadTemplateArgs
  >,
) {
  return useMutation(GET_CONTRACT_PART_UPLOAD_TEMPLETE, baseOptions)
}

const GET_CONTRACT_XREF_UPLOAD_TEMPLETE = gql`
  mutation getContractXrefUploadTemplate($contractId: String!) {
    getContractXrefUploadTemplate(contractId: $contractId)
  }
`

export function useGetContractXrefUploadTemplateMutation(
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'getContractXrefUploadTemplate'>,
    MutationGetContractXrefUploadTemplateArgs
  >,
) {
  return useMutation(GET_CONTRACT_XREF_UPLOAD_TEMPLETE, baseOptions)
}

const CONTRACT_ATTACHMENT_TYPES_QUERY = gql`
  query contractAttachmentTypes {
    contractAttachmentTypes
  }
`

export const useGetContractAttachmentTypes = (
  baseOptions: QueryHookOptions<Pick<Query, 'contractAttachmentTypes'>>,
) => {
  return useQuery(CONTRACT_ATTACHMENT_TYPES_QUERY, baseOptions)
}

const ADD_CONTRACT_TIER_REBATE = gql`
  mutation addContractTierRebate($input: CreateTierRebateInput!) {
    addContractTierRebate(input: $input)
  }
`

export function useAddContractTierRebateMutation(
  baseOptions?: MutationHookOptions<Pick<Mutation, 'addContractTierRebate'>, MutationAddContractTierRebateArgs>,
) {
  return useMutation(ADD_CONTRACT_TIER_REBATE, baseOptions)
}

const REMOVE_CONTRACT_TIER_REBATE = gql`
  mutation removeContractTierRebate($input: RemoveContractTierRebateInput!) {
    removeContractTierRebate(input: $input)
  }
`

export function useRemoveContractTierRebateMutation(
  baseOptions?: MutationHookOptions<Pick<Mutation, 'removeContractTierRebate'>, MutationRemoveContractTierRebateArgs>,
) {
  return useMutation(REMOVE_CONTRACT_TIER_REBATE, baseOptions)
}

const CONTRACT_PART_TIER_HISTORY_QUERY = gql`
  query partTiersHistories($tsId: String!) {
    partTiersHistories(tsId: $tsId) {
      id
      partTier {
        tier {
          id
          name
        }
        tiersId
        partsTsId
      }
      price
      effectiveStartDate
      effectiveEndDate
    }
  }
`

export const usePartTierHistoriesQuery = (
  baseOptions: QueryHookOptions<Pick<Query, 'partTiersHistories'>, QueryPartTiersHistoriesArgs>,
) => useQuery(CONTRACT_PART_TIER_HISTORY_QUERY, baseOptions)

const UPDATE_CONTRACT_PART_TIER_HISTORIES_MUTATION = gql`
  mutation updateContractPartTierHistories($contractId: String!, $input: [UpdatePartTierHistoryInput!]!) {
    updateContractPartTierHistories(contractId: $contractId, input: $input) {
      id
      price
      effectiveStartDate
      effectiveEndDate
    }
  }
`

export function useUpdateContractPartTierHistoriesMutation(
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'updateContractPartTierHistories'>,
    MutationUpdateContractPartTierHistoriesArgs
  >,
) {
  return useMutation(UPDATE_CONTRACT_PART_TIER_HISTORIES_MUTATION, baseOptions)
}

const REMOVE_CONTRACT_PART_TIER_HISTORIES_MUTATION = gql`
  mutation removeContractPartTierHistories($contractId: String!, $ids: [String!]!) {
    removeContractPartTierHistories(contractId: $contractId, ids: $ids) {
      ...PartFragmentWithPartTier
    }
  }
  ${PartFragmentWithPartTier}
`

export function useRemoveContractPartTierHistoriesMutation(
  baseOptions?: MutationHookOptions<
    Pick<Mutation, 'removeContractPartTierHistories'>,
    MutationRemoveContractPartTierHistoriesArgs
  >,
) {
  return useMutation(REMOVE_CONTRACT_PART_TIER_HISTORIES_MUTATION, baseOptions)
}
